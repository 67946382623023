<template>
  <section class="bg pg-height pd-20">
    <aForm
      @submit.prevent="onSubmitForm"
      @onFieldsChange="updateTempUser"
      :form="form"
    >
      <aRow align="top" :gutter="15">
        <aCol class="sidebar">
          <aRow :gutter="[20, 0]">
            <aCol class="mb-20" :span="24">
              <div class="w-box pd-0" style="overflow: hidden">
                <UserProfileSection v-if="tempUser.id" :tempUser="tempUser" />
              </div>
            </aCol>

            <aCol class="mb-20" :span="24">
              <div class="w-box">
                <UserMenuSection
                  :tempUser="tempUser"
                  @onClickActiveTab="onClickActiveTab"
                />
              </div>
            </aCol>

            <aCol class="mb-20" :span="24">
              <a-button
                class="page-button"
                type="primary"
                block
                :loading="loadingUserForm"
                html-type="submit"
              >
                <img
                  v-if="!loadingUserForm"
                  src="@/assets/images/dashboard/contracts/save.png"
                  width="20"
                  class="mr-10"
                  alt="save"
                />
                ATUALIZAR
              </a-button>
            </aCol>
          </aRow>
        </aCol>

        <aCol class="content" flex="auto">
          <div class="w-box">
            <div v-if="activeTab === 'Dados do Usuário'">
              <div class="title">
                <aRow type="flex" justify="space-between">
                  <aCol>
                    <h3>
                      <img
                        src="@/assets/images/dashboard/customers/user.png"
                        alt="travels"
                        width="15"
                      />
                      Dados do Usuário
                    </h3>
                  </aCol>
                  <aCol>
                    <aButton
                      class="f12 relative"
                      icon="lock"
                      size="small"
                      style="top: -5px"
                      @click="openPasswordModal = true"
                    >
                      Editar dados
                    </aButton>
                  </aCol>
                </aRow>
              </div>
              <UserDefaultData
                v-if="tempUser.id"
                :form="form"
                :tempUser="tempUser"
                :loadingUserForm="loadingUserForm"
                :disableUserData="disableUserData"
              />
            </div>

            <div v-if="activeTab === 'E-mail'">
              <div class="title">
                <h3>
                  <a-icon
                    class="f18 mr-5 relative"
                    type="mail"
                    style="color: #aaa; top: 2px"
                  />
                  E-mail
                </h3>
              </div>
              <UserEmailsTab
                v-if="tempUser.id"
                :loadingUserForm="loadingUserForm"
                :tempUser="tempUser"
                :form="form"
                :disableUserData="disableUserData"
              />
            </div>

            <div v-if="activeTab === 'Anotações'">
              <div class="title">
                <h3>
                  <img
                    src="@/assets/images/dashboard/customers/note.png"
                    alt="travels"
                    width="15"
                  />
                  Anotações
                </h3>
              </div>
              <Notes module="user" :moduleId="tempUser.id" :entry="tempUser" />
            </div>

            <div v-if="activeTab == 'Arquivos'">
              <div class="title">
                <h3>
                  <a-icon
                    class="f18 mr-5 relative"
                    type="paper-clip"
                    style="color: #aaa; top: 2px"
                  />
                  Arquivos
                </h3>
              </div>
              <FilesTable
                moduleName="user"
                :moduleId="tempUser.id"
                :customerId="tempUser.id"
                s3Folder="users"
                :searchCustomerId="`${tempUser.id}`"
              />
            </div>

            <div v-if="activeTab == 'Histórico'">
              <div class="title">
                <h3>
                  <img
                    src="@/assets/images/dashboard/customers/logs.png"
                    alt="travels"
                    width="15"
                  />
                  Logs
                </h3>
              </div>
              <LogsTimelineV2
                :userId="tempUser.id"
                color="green"
                style="margin-top: -10px"
              />
            </div>
          </div>
        </aCol>
      </aRow>

      <aModal
        :visible="openPasswordModal"
        :footer="false"
        :width="300"
        @cancel="openPasswordModal = false"
      >
        <template #title> <aIcon type="lock" /> Verificação </template>
        <LoginFormModal
          v-if="openPasswordModal"
          :updateStorageData="false"
          @verificationSuccessfully="verificationSuccessfully"
        />
      </aModal>
    </aForm>
  </section>
</template>

<script>
import UserProfileSection from "@/components/users/sections/UserProfileSection.vue";
import userMixins from "@/components/users/mixins/userMixins";
import UserMenuSection from "@/components/users/sections/UserMenuSection.vue";
import Notes from "@/components/general/Notes.vue";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";
import FilesTable from "@/components/general/FilesTable.vue";
import UserEmailsTab from "@/components/users/tabs/UserEmailsTab.vue";
import UserDefaultData from "@/components/users/tabs/UserDefaultData.vue";
import LoginFormModal from "@/components/login/LoginFormModal.vue";

export default {
  name: "UserPage",
  components: {
    UserProfileSection,
    UserMenuSection,
    Notes,
    LogsTimelineV2,
    FilesTable,
    UserEmailsTab,
    UserDefaultData,
    LoginFormModal,
  },
  mixins: [userMixins],
  data() {
    return {
      activeTab: "Dados do Usuário",
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempUser,
      }),
      loadingUserForm: false,
      openPasswordModal: false,
      disableUserData: false,
    };
  },
  mounted() {
    this.getUser(this.$route.params.id);
  },
  methods: {
    verificationSuccessfully() {
      this.openPasswordModal = false;
      this.disableUserData = false;
    },
    updateTempUser(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempUser[field] = value;

      if (Array.isArray(value)) {
        this.tempUser[field] = JSON.stringify(value);
      }
      this.tempUser = { ...this.tempUser };
    },
    onClickActiveTab(tab) {
      this.activeTab = tab;
    },
    updateLogs(data) {
      let log = "";
      let dataFromDatabase = {
        ...this.user.details.raw,
        ...this.user.details.meta,
      };

      const formatDateArr = ["birthday"];
      formatDateArr;
      for (const key in data) {
        if (data[key] !== dataFromDatabase[key]) {
          let theValue = data[key];
          let theValueFromDb = dataFromDatabase[key];

          if (
            ![
              "password",
              "userPassword",
              "mail_client_password",
              "twofa_secret",
              "modified_by",
            ].includes(key)
          ) {
            log += `<b>${this.translateKeyToLabel(key)
              .replace(/credit_letter_miles/g, "Carta de Crédito milhas")
              .replace(/supplier/g, "fornecedor")
              .replace(/value/g, "valor")
              .replace(/_/g, " ")}:</b> alterado de <b>${this.emptyValsFormat(
              theValueFromDb
            )}</b> para <b>${this.emptyValsFormat(theValue)}</b>;<br>`;
          }
        }
      }

      if (log)
        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: data.id,
          module: "user",
          action: "update",
          description: `<div class="f12 mb-10">Alterações:</div>
          <div class="f12 mb-10">${log} </div>
          <div class="f12 mb-10"> <b>Por:</b> ${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</div>`,
        });
    },
    emptyValsFormat(val) {
      let theVal = val;

      if ([undefined, "undefined", null, "null"].includes(val)) theVal = "' '";

      return theVal;
    },
    translateKeyToLabel(key) {
      let theLabel = key;
      return theLabel;
    },

    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values, this.tempUser);
        if (!err) {
          if (values.userPassword && values.password) {
            values.password;
          } else {
            values.password = "";
          }
          this.updateLogs(this.tempUser);

          this.tempUser.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/user/update", this.tempUser)
            .then(({ data }) => {
              this.$message.success(data.message);

              this.loadingUserForm = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingUserForm = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  img
    margin-right: 5px
  h3
    font-size: 14px
    font-weight: 500
.sidebar
  float: left
  width: 360px

.content
  float: left
  width: calc(100% - 360px)

.page-button
  padding: 12px
  font-weight: 500
  height: 59px
  font-size: 20px
</style>
