<template>
  <div>
    <aRow :gutter="20">
      <aCol :span="6">
        <HayaDatePickerField
          label="Data de admissão"
          placeholder="Selecione..."
          fieldKey="admission_date"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'##/##/####'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="6">
        <HayaDatePickerField
          label="Data de demissão"
          placeholder="Selecione..."
          fieldKey="resignation_date"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'##/##/####'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="24">
        <h2><a-icon type="idcard" /> Carteira de Trabalho</h2>
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="NIS/PIS/PASEP"
          placeholder="xxx.xxxxx.xx-x"
          fieldKey="nis_pis"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'###.#####.##-#'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="4">
        <HayaMaskedTextField
          label="Número"
          placeholder="xxxxxxx"
          fieldKey="work_card_number"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'#######'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="4">
        <HayaMaskedTextField
          label="Série"
          placeholder="xxx-x"
          fieldKey="work_card_series_number"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'###-#'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="4">
        <HayaSelectField
          label="Estado da emissão"
          fieldKey="work_card_state"
          :list="mapStatesList(states)"
          :required="false"
          :form="form"
          :allowClear="true"
          :showSearch="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>
    </aRow>
  </div>
</template>

<script>
import HayaMaskedTextField from "@/components/general/fields/HayaMaskedTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaDatePickerField from "@/components/general/fields/HayaDatePickerField.vue";

import userMixins from "../mixins/userMixins";
import states from "@/json/states";

export default {
  name: "UserDefaultData",
  props: {
    tempUser: Object,
    form: Object,
    disableUserData: Boolean,
  },
  mixins: [userMixins],
  components: {
    HayaSelectField,
    HayaDatePickerField,
    HayaMaskedTextField,
  },
  data() {
    return { states };
  },
  mounted() {
    this.form.setFieldsValue(this.tempUser);
  },
  methods: {
    mapStatesList(arr) {
      return arr.map(({ name, initials }) => {
        return {
          label: name,
          value: initials,
        };
      });
    },
  },
};
</script>

<style lang="sass" scoped>
h2
  font-size: 14px
  margin: 0 0 30px
  padding: 0 0 7px
  border-bottom: 1px solid #f0f0f0
.label
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
  margin-top: -10px
  margin-bottom: 10px
  display: block
</style>
