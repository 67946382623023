<template>
  <div>
    <a-row>
      <a-col v-for="(item, index) in menu" :key="index" :span="24">
        <div
          v-if="item.showItem"
          class="row border c-pointer hover"
          :class="`${item.extraClass} ${
            item.disabled ? 'disabled grayscale' : ''
          }`"
          @click="onClickActiveTab(item.name)"
        >
          <span class="txt">
            <a-row type="flex" justify="space-between">
              <a-col>
                <span class="img">
                  <img
                    v-if="item.img"
                    :src="item.img"
                    :alt="item.name"
                    width="15"
                  />
                  <a-icon
                    class="relative"
                    v-if="item.ico"
                    :type="item.ico"
                    style="top: 2px"
                  />
                </span>
                {{ item.name }}
              </a-col>
              <!-- <a-col
                v-if="
                  item.name == 'Dados Pessoais' &&
                  errorLog.customerData.length > 0
                "
              >
                <a-tooltip>
                  <template slot="title">
                    {{ item.alertMsg }}
                  </template>
                  <img
                    class="c-pointer"
                    src="@/assets/images/dashboard/contracts/danger.png"
                    alt="img"
                    width="15"
                  />
                </a-tooltip>
              </a-col> -->
            </a-row>
          </span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// images
import user from "@/assets/images/dashboard/customers/user.png";
import opportunity from "@/assets/images/dashboard/customers/opportunity.png";
import travels from "@/assets/images/dashboard/customers/travels.png";
import note from "@/assets/images/dashboard/customers/note.png";
import logs from "@/assets/images/dashboard/customers/logs.png";

export default {
  name: "UserMenuSection",

  data() {
    return {
      menu: [
        {
          name: "Dados do Usuário",
          img: user,
          ico: "",
          showItem: true,
          disabled: false,
          action: this.onClickOpenCustomerData,
          extraClass: "",
          alertMsg:
            "Clique para verificar todos os dados obrigatórios do cliente",
        },
        {
          name: "Oportunidades",
          img: opportunity,
          ico: "",
          showItem: true,
          disabled: true,
          action: false,
          extraClass: "",
        },
        {
          name: "Contratos",
          img: travels,
          ico: "",
          showItem: true,
          disabled: true,
          action: false,
          extraClass: "",
        },
        {
          name: "E-mail",
          img: "",
          ico: "mail",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Arquivos",
          img: false,
          ico: "paper-clip",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Anotações",
          img: note,
          ico: "",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Histórico",
          img: logs,
          ico: "",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: " no-border ",
        },
      ],
    };
  },

  mounted() {},
  methods: {
    onClickActiveTab(tab) {
      this.$emit("onClickActiveTab", tab);
    },
  },
};
</script>

<style lang="sass" scoped>
.row
  padding: 5px 10px
  font-size: 13px
  font-weight: 500
  width: 314px
  transition: .3s
  max-width: 100%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  .img
    width: 20px
    display: inline-block
    .anticon
      opacity: 0.45
      font-size: 17px

  &.hover:hover
    background: rgba(0,0,0,0.02)
    color: #ff8000
    .img
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
  &.border
    border-bottom: 1px solid #d6d6d6
    padding: 10px 5px
  &.disabled
    .txt
      opacity: 0.3
  &::last-child
    border-bottom: 0
  &.no-border
    border: 0
    padding: 10px  5px
  &.bold
    font-weight: 600
</style>
