<template>
  <aRow :gutter="20">
    <aCol :span="6">
      <HayaMaskedTextField
        label="CEP"
        placeholder="Insira"
        fieldKey="cep"
        :required="true"
        :vMask="'#####-###'"
        :form="form"
        :preIcon="true"
        :fieldClass="disabled ? 'readonly' : ''"
        @onBlurTextField="onBlurGetLocation"
      />
    </aCol>

    <aCol :span="14">
      <HayaTextField
        label="Logradouro"
        placeholder="Ex: Rua..."
        fieldKey="location_street"
        :required="true"
        :form="form"
        :preIcon="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>

    <aCol :span="4">
      <HayaTextField
        label="Número"
        placeholder="Insira"
        fieldKey="location_number"
        :required="true"
        :form="form"
        :preIcon="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>

    <aCol :span="6">
      <HayaTextField
        label="Complemento"
        placeholder="Escreva..."
        fieldKey="location_complement"
        :required="true"
        :form="form"
        :preIcon="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>

    <aCol :span="6">
      <HayaTextField
        label="Bairro"
        placeholder="Escreva..."
        fieldKey="location_neighborhood"
        :required="true"
        :form="form"
        :preIcon="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>

    <aCol :span="6">
      <HayaTextField
        label="Cidade"
        placeholder="Escreva..."
        fieldKey="location_city"
        :required="true"
        :form="form"
        :preIcon="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>

    <aCol :span="6">
      <HayaSelectField
        label="Estado"
        fieldKey="location_state"
        :list="mapStatesList(states)"
        :required="true"
        :form="form"
        :allowClear="true"
        :showSearch="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>

    <aCol :span="6">
      <HayaSelectField
        label="País"
        fieldKey="location_country"
        :list="mapCountriesList(countries)"
        :required="true"
        :form="form"
        :allowClear="true"
        :showSearch="true"
        :fieldClass="disabled ? 'readonly' : ''"
      />
    </aCol>
  </aRow>
</template>

<script>
import axios from "axios";
import HayaSelectField from "./fields/HayaSelectField.vue";
import HayaTextField from "./fields/HayaTextField.vue";
import HayaMaskedTextField from "./fields/HayaMaskedTextField.vue";

import states from "@/json/states";
import countries from "@/json/countries";

export default {
  name: "AddressDataV2",
  props: {
    form: Object,
    data: Object,
    disabled: Boolean,
  },
  components: { HayaTextField, HayaSelectField, HayaMaskedTextField },
  data() {
    return { states, countries, cepLoading: false };
  },
  mounted() {
    this.form.setFieldsValue(this.data);
  },
  methods: {
    onBlurGetLocation(e) {
      axios
        .get("https://viacep.com.br/ws/" + e.target.value + "/json/")
        .then(({ data }) => {
          this.cepLoading = false;

          if (data.erro === undefined) {
            this.form.setFieldsValue({
              location_street: data.logradouro,
            });

            this.form.setFieldsValue({
              location_state: data.uf,
            });

            this.form.setFieldsValue({
              location_complement: data.complemento,
            });

            this.form.setFieldsValue({
              location_city: data.localidade,
            });

            this.form.setFieldsValue({
              location_neighborhood: data.bairro,
            });

            this.form.setFieldsValue({
              location_country: "Brasil",
            });
          } else {
            this.$message.error("Este CEP não existe.");
          }
        })
        .catch(() => {
          this.$message.error("Houve um problema ao recuperar a localização.");
          this.cepLoading = false;
        });
    },
    mapStatesList(arr) {
      return arr.map(({ name, initials }) => {
        return {
          label: name,
          value: initials,
        };
      });
    },
    mapCountriesList(arr) {
      return arr.map(({ Pais }) => {
        return {
          label: Pais,
          value: Pais,
        };
      });
    },
  },
};
</script>
