<template>
  <div>
    <aRow :gutter="20">
      <aCol :span="24">
        <h2><a-icon type="user" /> Filiação</h2>
      </aCol>

      <aCol :span="9">
        <HayaTextField
          label="Nome da mãe"
          placeholder="Escreva..."
          fieldKey="mothers_name"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="9">
        <HayaTextField
          label="Nome do pai"
          placeholder="Escreva..."
          fieldKey="fathers_name"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Telefone de emergência"
          placeholder="Escreva..."
          fieldKey="emergency_phone"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'(##) # ####-####'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="24">
        <h2><a-icon type="idcard" /> Título Eleitoral</h2>
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Número do título de eleitor"
          placeholder="Escreva..."
          fieldKey="voter_registration_number"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'#### #### ####'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Zona"
          placeholder="Escreva..."
          fieldKey="voter_zone_number"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'###'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Seção"
          placeholder="Escreva..."
          fieldKey="voter_section_number"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'####'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="24">
        <h2><a-icon type="user" /> Robbu</h2>
      </aCol>

      <aCol :span="9">
        <HayaTextField
          label="Nome do usuário Robbu"
          placeholder="Escreva..."
          fieldKey="user_robbu"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>
    </aRow>
  </div>
</template>

<script>
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaMaskedTextField from "@/components/general/fields/HayaMaskedTextField.vue";

import userMixins from "../mixins/userMixins";

export default {
  name: "UserExtraDataTab",
  props: {
    tempUser: Object,
    form: Object,
    disableUserData: Boolean,
  },
  mixins: [userMixins],
  components: {
    HayaTextField,
    HayaMaskedTextField,
  },
  mounted() {
    this.form.setFieldsValue(this.tempUser);
  },
};
</script>

<style lang="sass" scoped>
h2
  font-size: 14px
  margin: 0 0 30px
  padding: 0 0 7px
  border-bottom: 1px solid #f0f0f0
.label
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
  margin-top: -10px
  margin-bottom: 10px
  display: block
</style>
