<template>
  <div>
    <aTabs class="user-tabs mb-10" v-model="activeUserTab" type="card">
      <aTabPane v-for="tab in userTabs" :key="tab.name" :tab="tab.name" />
    </aTabs>

    <UserDefaultDataTab
      v-if="activeUserTab === 'DADOS BÁSICOS'"
      :tempUser="tempUser"
      :form="form"
      :disableUserData="disableUserData"
    />

    <AddressDataV2
      :data="tempUser"
      :form="form"
      :disabled="disableUserData"
      v-if="activeUserTab === 'ENDEREÇO'"
    />

    <UserCompanyDataTab
      v-if="activeUserTab === 'EMPRESA'"
      :tempUser="tempUser"
      :form="form"
      :disableUserData="disableUserData"
    />

    <UserExtraDataTab
      v-if="activeUserTab === 'OUTROS'"
      :tempUser="tempUser"
      :form="form"
      :disableUserData="disableUserData"
    />
  </div>
</template>

<script>
import userMixins from "../mixins/userMixins";
import UserDefaultDataTab from "./UserDefaultDataTab.vue";
import UserCompanyDataTab from "./UserCompanyDataTab.vue";
import UserExtraDataTab from "./UserExtraDataTab.vue";
import AddressDataV2 from "@/components/general/AddressDataV2.vue";

export default {
  name: "UserDefaultData",
  props: {
    tempUser: Object,
    form: Object,
    disableUserData: Boolean,
  },
  mixins: [userMixins],
  components: {
    UserDefaultDataTab,
    UserCompanyDataTab,
    UserExtraDataTab,
    AddressDataV2,
  },
  data() {
    return {
      activeUserTab: "DADOS BÁSICOS",
      userTabs: [
        {
          name: "DADOS BÁSICOS",
          disabled: false,
        },
        {
          name: "ENDEREÇO",
          disabled: false,
        },
        {
          name: "EMPRESA",
          disabled: false,
        },
        {
          name: "OUTROS",
          disabled: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass">
.user-tabs
  .ant-tabs-tab
    font-size: 12px
</style>

<style lang="sass" scoped>
.user-tabs
  margin-top: -10px
</style>
