<template>
  <div>
    <aRow :gutter="20">
      <aCol :span="6">
        <HayaTextField
          label="Nome"
          placeholder="Escreva..."
          fieldKey="first_name"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Sobrenome"
          placeholder="Escreva..."
          fieldKey="last_name"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="8">
        <HayaTextField
          label="E-mail"
          placeholder="Escreva..."
          fieldKey="email"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :rules="[
            {
              type: 'email',
              message: 'Escreva um e-mail válido',
            },
          ]"
        />
      </aCol>

      <aCol :span="4">
        <div class="label">Status</div>
        <a-checkbox
          v-model="userStatus"
          @change="onChangeStatus"
          class="f12 relative fw600"
          :class="disableUserData ? 'readonly' : ''"
        >
          Ativo
        </a-checkbox>
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Sexo"
          fieldKey="gender"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :list="[
            {
              label: 'Masculino',
              value: 'Masculino',
            },
            {
              label: 'Feminino',
              value: 'Feminino',
            },
          ]"
        />
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Estado civil"
          fieldKey="marital_status"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :list="[
            {
              label: 'Casado',
              value: 'Casado',
            },
            {
              label: 'Divorciado',
              value: 'Divorciado',
            },
            {
              label: 'Separado',
              value: 'Separado',
            },
            {
              label: 'Solteiro',
              value: 'Solteiro',
            },
            {
              label: 'Viúvo',
              value: 'Viúvo',
            },
          ]"
        />
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Função do usuáriox"
          fieldKey="role"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :list="
            roles.map((item) => {
              return {
                label: item.name,
                value: item.value,
              };
            })
          "
        />
      </aCol>

      <aCol :span="6">
        <HayaDatePickerField
          label="Nascimento"
          placeholder="Selecione..."
          fieldKey="birthday"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :vMask="'##/##/####'"
        />
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="24">
        <h2><a-icon type="idcard" /> Identificação</h2>
      </aCol>
      <aCol :span="6">
        <HayaMaskedTextField
          label="CPF"
          placeholder="Escreva..."
          fieldKey="cpf"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :vMask="'###.###.###-##'"
        />
      </aCol>

      <aCol :span="5">
        <HayaMaskedTextField
          label="Número do RG"
          placeholder="Escreva..."
          fieldKey="rg"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :vMask="[
            '##.###.###',
            '#.###.###',
            '###.###.###-##',
            '###.###.###.###-##',
          ]"
        />
      </aCol>

      <aCol :span="3">
        <HayaTextField
          label="Orgão Emissor"
          placeholder="Escreva..."
          fieldKey="rg_emissor"
          :required="false"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="4">
        <HayaSelectField
          label="Estado da emissão"
          fieldKey="rg_state"
          :list="mapStatesList(states)"
          :required="false"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaDatePickerField
          label="Data de expedição"
          placeholder="Selecione..."
          fieldKey="rg_emission_date"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :vMask="'##/##/####'"
        />
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="24">
        <h2><aIcon type="phone" /> Contatos</h2>
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Celular (Empresa)"
          placeholder="Escreva..."
          fieldKey="mobile_phone"
          :form="form"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :preIcon="true"
          :vMask="'(##) # ####-####'"
        />
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Telefone Fixo/Ramal (Empresa)"
          placeholder="Escreva..."
          fieldKey="phone"
          :required="false"
          :form="form"
          :preIcon="true"
          :vMask="'(##) ####-####'"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Celular (Pessoal)"
          placeholder="Escreva..."
          fieldKey="personal_mobile_phone"
          :required="false"
          :form="form"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :preIcon="true"
          :vMask="'(##) # ####-####'"
        />
      </aCol>

      <aCol :span="6">
        <HayaMaskedTextField
          label="Telefone residencial (Pessoal)"
          placeholder="Escreva..."
          fieldKey="personal_phone"
          :required="false"
          :form="form"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :preIcon="true"
          :vMask="'(##) ####-####'"
        />
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="24">
        <h2><a-icon type="lock" /> Senha</h2></aCol
      >
      <aCol :span="6">
        <HayaPasswordField
          label="Senha"
          placeholder="Insira a senha..."
          fieldKey="userPassword"
          :required="false"
          :form="form"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaPasswordField
          label="Repita a senha"
          placeholder="Repita a senha..."
          fieldKey="password"
          :required="false"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
          :rules="[
            {
              validator: compareToFirstPassword,
            },
          ]"
        />
      </aCol>
      <aCol v-if="tempUser.twofa_secret" :span="6">
        <aPopconfirm
          ok-text="Sim"
          cancel-text="Não"
          placement="top"
          @confirm="resetUser2Factors"
        >
          <template #title>
            Tem certeza que deseja resetar <br />
            o códgio 2FA do usuário?
          </template>
          <aButton
            class="f12 relative"
            icon="qrcode"
            style="top: 5px"
            :loading="loadingReset2FA"
          >
            Segurança
          </aButton>
        </aPopconfirm>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaMaskedTextField from "@/components/general/fields/HayaMaskedTextField.vue";
import HayaPasswordField from "@/components/general/fields/HayaPasswordField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaDatePickerField from "@/components/general/fields/HayaDatePickerField.vue";

import roles from "@/json/roles.json";
import userMixins from "../mixins/userMixins";
import states from "@/json/states";

export default {
  name: "UserDefaultData",
  props: {
    tempUser: Object,
    form: Object,
    disableUserData: Boolean,
  },
  mixins: [userMixins],
  components: {
    HayaTextField,
    HayaPasswordField,
    HayaSelectField,
    HayaDatePickerField,
    HayaMaskedTextField,
  },
  data() {
    return {
      userStatus: false,
      loadingReset2FA: false,
      states,
      roles,
    };
  },
  mounted() {
    this.form.setFieldsValue(this.tempUser);

    this.userStatus = this.tempUser.status == 1 ? true : false;

    setTimeout(() => {
      this.form.setFieldsValue({
        password: undefined,
        userPassword: undefined,
      });
    }, 700);
  },
  methods: {
    resetUser2Factors() {
      this.loadingReset2FA = true;
      this.$hayaApi
        .post("/2fa/reset", {
          email: this.tempUser.email,
        })
        .then(({ data }) => {
          this.$message.success(data.message, 5);
          this.tempUser.twofa_secret = false;

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempUser.id,
            module: "user",
            action: "reset-2fa",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} limpou o código 2FA do usuário ID ${this.tempUser.id}.`,
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => (this.loadingReset2FA = false));
    },
    onChangeStatus(status) {
      this.tempUser["status"] = status.target.checked;
    },
    mapStatesList(arr) {
      return arr.map(({ name, initials }) => {
        return {
          label: name,
          value: initials,
        };
      });
    },
    async compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("userPassword")) {
        callback("As senhas digitadas não são iguais.");
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
h2
  font-size: 14px
  margin: 0 0 30px
  padding: 0 0 7px
  border-bottom: 1px solid #f0f0f0
.label
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
  margin-top: -10px
  margin-bottom: 10px
  display: block
</style>
