<template>
  <div>
    <div class="table-operations a-right mb-10">
      <a-upload
        :multiple="true"
        :showUploadList="false"
        :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=${s3Folder}&id=${moduleId}`"
        :headers="{
          authorization: `Bearer ${this.$store.state.token}`,
        }"
        @change="uploadFile"
        :disabled="loading"
      >
        <a-button class="f13" type="primary" :loading="loading">
          <a-icon type="file-add" /> Enviar arquivos
        </a-button>
      </a-upload>
    </div>
    <a-table
      class="travel-table"
      :columns="filesTableColumns"
      :data-source="file.list"
      :loading="file.loading"
      :pagination="false"
      :scroll="{ x: 800 }"
      size="middle"
      @change="fileTableChange"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <div slot="size" slot-scope="record">
        {{ record.size | formatFileSize }}
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div slot="created" slot-scope="record">
        {{ record.created | formatMultiDates }}
      </div>

      <div class="travel-actions f14" slot="action" slot-scope="record">
        <a-icon
          v-if="record.extension !== 'application/pdf'"
          @click="onClickPreviewFile(record)"
          class="mr-10"
          type="eye"
        />

        <a-icon
          class="cgreen c-pointer mr-10"
          type="download"
          @click="downloadFile(record)"
        />

        <a-popconfirm
          title="Tem certeza que deseja apagar o arquivo?"
          ok-text="Sim"
          cancel-text="Não"
          placement="left"
          @confirm="deleteFile(record.id)"
        >
          <a-icon class="red c-pointer" type="delete" />
        </a-popconfirm>
      </div>
    </a-table>
    <div class="mt-20 a-center">
      <a-pagination
        class="f12"
        show-size-changer
        :default-current="file.pagination.page"
        :page-size.sync="file.pagination.perPage"
        :total="file.pagination.total"
        @change="changeFilePage"
        @showSizeChange="changeFilePageSize"
      >
      </a-pagination>
    </div>

    <a-modal
      :visible="previewFile"
      :footer="false"
      @cancel="previewFile = false"
      :width="800"
    >
      <template #title> {{ theFile.name }} </template>
      <div class="a-center">
        <div
          v-if="
            theFile.extension === 'image/png' ||
            theFile.extension === 'image/jpeg'
          "
        >
          <img :src="theFile.url" style="max-width: 100%" alt="img" />
        </div>
        <div v-if="theFile.extension === 'application/pdf'">

        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import filesMixins from "@/mixins/files/filesMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "FilesTable",
  props: {
    moduleName: String,
    moduleId: String,
    customerId: String,
    searchModules: String,
    searchModuleIds: String,
    searchCustomerId: String,
    s3Folder: String,
  },
  components: {  },
  mixins: [filesMixins, formatThings],
  data() {
    return {
      loading: false,
      previewFile: false,
      theFile: {},
    };
  },
  mounted() {
      this.file.filters.customerId = this.searchCustomerId;
      this.file.filters.id = this.searchModuleIds;
      this.file.filters.module = this.searchModules;

    this.getFiles();
  },
  filters: {
    formatFileSize(bytes) {
      // Initialize the output value
      let output = bytes;

      // If the value is greater than 1024, convert it to KB
      if (bytes > 1024) {
        output = bytes / 1024;
        output = output.toFixed(2);
        output += " KB";
      }

      // If the value is greater than 1048576, convert it to MB
      if (bytes > 1048576) {
        output = bytes / 1048576;
        output = output.toFixed(2);
        output += " MB";
      }

      return output;
    },
  },
  methods: {
    onClickPreviewFile(file) {
      this.theFile = file;
      this.previewFile = true;
    },
    downloadFile(file) {
      window.open(file.url);
      this.$http.post("/log/create", {
        user_id: this.$store.state.userData.id,
        module_id: this.moduleId,
        module: this.moduleName,
        action: "download-file",
        description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} baixou o arquivo <b>${file.name}</b>.`,
      });
    },
    deleteFile(id) {
      this.$http.post(`/file/delete?id=${id}`).then(({ data }) => {
        this.$message.success(data.message);
        this.getFiles();
      });
    },

    uploadFile(response) {
      this.loading = true;
      if (response.file.response != undefined) {
        if (response.file.response.url != undefined) {
          this.$http
            .post("/file/create", {
              user_id: this.$store.state.userData.id,
              customer_id: this.customerId,
              module_id: this.moduleId,
              module: this.moduleName,
              name: response.file.name,
              extension: response.file.type,
              size: response.file.size,
              url: response.file.response.url,
            })
            .then(({ data }) => {
              this.$message.success(data.message);
              this.getFiles();
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
td.ant-table-row-cell-break-word
  padding: 0 10px !important
</style>
