<template>
  <section>
    <div class="system-logs" v-if="log.list.length > 0">
      <aTimeline class="pd-20 pl-10">
        <a-timeline-item
          v-for="(item, index) in log.list"
          :key="index"
          :color="color"
        >
          <aIcon
            slot="dot"
            style="color: red"
            type="fire"
            v-if="item.module === 'opportunity'"
          />
          <aIcon
            slot="dot"
            style="color: #52c41a"
            type="file-text"
            class="f16"
            v-if="item.module === 'contract'"
          />

          <aIcon
            slot="dot"
            style="color: #0083cd"
            type="shopping"
            class="f16"
            v-if="item.module === 'sale'"
          />

          <aTag
            v-if="showAction"
            :color="theColor(item.module)"
            class="ml-10 mr-10"
          >
            {{ item.action }}
          </aTag>
          <span class="f12 cblack" v-html="item.description" />
          <div style="margin: -5px 0 0 -1px">
            <span class="f10" style="color: #aaa">
              <aIcon type="clock-circle" class="cgray" />
              {{ formatDate(item.created) }}

              <a-divider type="vertical" />
              {{ formatHayaModules(item.module) }}
            </span>
          </div>
        </a-timeline-item>
      </aTimeline>

      <div class="mt-20">
        <a-pagination
          show-size-changer
          :default-current="log.pagination.page"
          :page-size-options="pageSizeOptions"
          :page-size.sync="log.pagination.perPage"
          :total="log.pagination.total"
          @change="changeLogPage"
          @showSizeChange="changeLogPageSize"
        >
        </a-pagination>
      </div>
    </div>
    <div v-else class="no-results mt-60">
      <aEmpty description="Nenhuma alteração realizada até o momento." />
    </div>
  </section>
</template>

<script>
import { format, parse } from "date-fns";
import logsMixins from "@/mixins/logs/logsMixins";
import modules from "@/json/hayaModules.json";

export default {
  mixins: [logsMixins],
  props: {
    id: String,
    module: String,
    userId: String,
    showAction: Boolean,
    color: String,
    perPage: Number,
    actionContains: String,
  },
  data() {
    return {
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000"],
      modules,
    };
  },
  mounted() {
    this.log.pagination.perPage = this.perPage ? this.perPage : 10;
    if (this.id) this.log.filters.id = this.id;
    if (this.module) this.log.filters.module = this.module;
    if (this.actionContains)
      this.log.filters.actionContains = this.actionContains;
    if (this.userId) this.log.filters.users.selected = this.userId;
    this.getLogs();
  },
  methods: {
    theColor(module) {
      let color = "#4c388e";
      if (module === "opportunity") color = "#4a89dc";
      return color;
    },
    formatHayaModules(module) {
      let theModule = "";

      if (module) {
        let selectedModule = this.modules.filter((mod) => {
          return mod.value == module;
        });

        theModule = selectedModule.length > 0 ? selectedModule[0].name : module;
      }

      return theModule;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
};
</script>

<style lang="sass">
.system-logs
  .ant-timeline-item-content
    margin: 0 0 0 28px
</style>

<style lang="sass" scoped>
pre
  max-width: 600px

.ant-timeline
  min-height: 427px
  max-height: 425px
  overflow: auto
</style>
