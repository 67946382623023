<template>
  <section>
    <aRow :gutter="20">
      <aCol :span="12">
        <HayaTextField
          label="E-mail/Username"
          placeholder="Ex: seuemail@voesimples.com..."
          fieldKey="mail_client_email"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="12">
        <HayaPasswordField
          label="Senha"
          placeholder="Escreva..."
          fieldKey="mail_client_password"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="12">
        <HayaTextField
          label="Servidor de entrada"
          placeholder="Ex: mailer.viajar..."
          fieldKey="mail_client_server"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="12">
        <HayaTextField
          label="Porta (IMAP)"
          placeholder="Ex: 993"
          fieldKey="mail_client_port"
          :required="true"
          :form="form"
          :preIcon="true"
          :fieldClass="disableUserData ? 'readonly' : ''"
        />
      </aCol>
    </aRow>
  </section>
</template>

<script>
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaPasswordField from "@/components/general/fields/HayaPasswordField.vue";

export default {
  name: "UserEmailsTab",
  props: {
    tempUser: Object,
    form: Object,
    isFirstConfig: Boolean,
    loadingUserForm: Boolean,
    disableUserData: Boolean,
  },
  components: { HayaTextField, HayaPasswordField },
  data() {
    return {};
  },
  mounted() {
    this.form.setFieldsValue(this.tempUser);
    setTimeout(() => {
      this.form.setFieldsValue({
        mail_client_password:
          this.$store.state.userData.id === this.tempUser.id
            ? this.tempUser.mail_client_password
            : undefined,
      });
    }, 500);
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.email-form-section
  background: #fafafa
  padding: 100px 0
.w-box
  margin: 0 auto
  width: 600px
  border: 1px solid #eee
  border-radius: 6px
  padding: 40px 20px 0px 20px
  background: #fff
h2
  font-size: 14px
  margin: 0 0 30px
  line-height: 1
  padding: 0 0 10px
  border-bottom: 1px solid #eee
</style>
