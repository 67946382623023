<template>
  <section class="user-details">
    <div
      class="cover"
      :style="`background: #ddd  url(${userCover}) no-repeat center`"
    >
      <a-button
        v-if="userCover"
        class="remove"
        shape="circle"
        icon="delete"
        @click="onClickRemoveCover"
      />

      <a-upload
        v-if="[undefined, ''].includes(userCover)"
        name="file"
        :multiple="false"
        :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=users/cover&id=${$store.state.userData.id}&sub-folder=`"
        :headers="{
          authorization: `Bearer ${this.$store.state.token}`,
        }"
        @change="uploadedCover"
      >
        <a-button class="add" shape="circle" icon="picture" />
      </a-upload>
    </div>

    <div class="infos">
      <div class="avatar">
        <span class="avatar-wrapper">
          <a-button
            v-if="tempUser.avatar"
            class="remove"
            shape="circle"
            icon="delete"
            @click="onClickRemoveAvatar"
          />
          <a-avatar
            v-if="tempUser.avatar"
            class="border"
            :size="120"
            icon="user"
            :src="tempUser.avatar"
          />

          <a-upload
            v-if="tempUser.avatar === ''"
            name="file"
            :multiple="false"
            :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=users/avatar&id=${$store.state.userData.id}&sub-folder=`"
            :headers="{
              authorization: `Bearer ${this.$store.state.token}`,
            }"
            @change="uploadedAvatar"
          >
            <a-avatar class="border c-pointer" :size="120" icon="user" />
          </a-upload>
        </span>
      </div>
      <div class="name">{{ tempUser.first_name }} {{ tempUser.last_name }}</div>
      <div class="id">
        ID {{ tempUser.id }} <a-divider type="vertical" />
        <a-tag class="status f10" v-if="tempUser.status == 1" color="#87d068">
          {{ tempUser.status | formatUserStatus }}
        </a-tag>
        <a-tag class="status f10" v-if="tempUser.status == 0" color="#cccccc">
          {{ tempUser.status | formatUserStatus }}
        </a-tag>
      </div>
      <div class="role">
        {{ tempUser.role | formatUserRole }} <a-divider type="vertical" /> Desde
        {{ formatDateTime(tempUser.created) }}
      </div>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "UserProfileSection",
  props: {
    tempUser: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      userCover: undefined,
    };
  },
  filters: {
    formatUserStatus(status) {
      return status == 1 ? "Ativo" : "Desativado";
    },
  },
  mounted() {
    this.userCover = this.tempUser.cover;
  },
  methods: {
    onClickRemoveAvatar() {
      this.tempUser.avatar = "";
    },
    onClickRemoveCover() {
      this.tempUser.cover = "";
      this.userCover = "";
    },
    uploadedAvatar(response) {
      if (response.file.response.url != undefined) {
        this.tempUser.avatar = response.file.response.url;
        this.$store.state.userData.meta.avatar = response.file.response.url;
      }
    },
    uploadedCover(response) {
      if (response.file.response.url != undefined)
        this.tempUser.cover = response.file.response.url;
      this.userCover = response.file.response.url;
    },
  },
};
</script>

<style lang="sass" scoped>
.user-details
  .cover
    height: 140px
    background: #ddd
    position: relative
    background-size: cover !important
    .add
      right: 10px
      top: 10px
      position: absolute
      z-index: 1
    .remove
      position: absolute
      z-index: 1
      right: 10px
      top: 10px
      color: red
  .infos
    background: #fff
    padding: 10px
    .name
      text-align: center
      text-transform: uppercase
      padding: 0 10px
      font-size: 16px
      font-weight: 600
      margin: 10px 0 3px
      line-height: 1
    .id
      color: #aaa
      margin-bottom: 10px
      text-align: center
      .status
        position: relative
        top: -2px
        padding: 3px 5px
        line-height: 1
    .role
      font-size: 9px
      color: #aaa
      text-align: center
    .avatar
      text-align: center
      margin-top: -50px
      .avatar-wrapper
        display: inline-block
        position: relative
        .remove
          top: 0
          z-index: 1
          position: absolute
          color: red
      .border
        border: 6px solid #FFF
</style>
